@charset "utf-8";
/*
 Knight Bootstrap theme by BootstrapTaste.com
 Theme URI: http://bootstraptaste.com/knight-free-bootstrap-theme/
 Author: BootstrapTaste
 Author URI: http://bootstraptaste.com
 */

/* CSS Document */

a {
  text-decoration: none;
}

/* Float Elements 
---------------------------------*/
.fl-lt {
  float: left;
}
.fl-rt {
  float: right;
}

/* Clear Floated Elements
---------------------------------*/
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:before,
.clearfix:after {
  content: "\0020";
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.figure {
  margin: 0px;
}

img {
  max-width: 100%;
}

a,
a:hover,
a:active {
  outline: 0px !important;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../fonts/fontawesome-webfont.eot?v=4.1.0");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.1.0")
      format("embedded-opentype"),
    url("../fonts/fontawesome-webfont.woff?v=4.1.0") format("woff"),
    url("../fonts/fontawesome-webfont.ttf?v=4.1.0") format("truetype"),
    url("../fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Primary Styles
---------------------------------*/
html {
  max-width: 100%;
}

body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #888888;
  margin: 0;
  max-width: 100%;
}
h2 {
  font-size: 34px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -1px;
  margin: 0 0 15px 0;
  text-align: center;
  text-transform: uppercase;
}
h3 {
  font-family: "Montserrat", sans-serif;
  color: #222222;
  font-size: 16px;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  font-weight: 400;
}
h6 {
  font-size: 16px;
  color: #888888;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 0 0 60px 0;
}
p {
  line-height: 24px;
  margin: 0;
}

/* Header Styles
---------------------------------*/

.header {
  text-align: center;
  background: url(../img/pw_maze_black_2X.png) left top repeat;
  padding: 280px 0;
  left: 50%;
  top: 50%;
}
.header-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.logo {
  width: 130px;
  margin: 0 auto 35px;
}
.header h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -1px;
  margin: 0 0 22px 0;
  color: #fff;
}

.we-create {
  padding: 0;
  margin: 35px 0 55px;
}
.wp-pic {
  margin-bottom: 20px;
}
.we-create li {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #bcbcbc;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 5px 0 0;
  padding: 0 0 0 15px;
}
.we-create li:first-child {
  background: none;
}

.start-button {
  padding-left: 0px;
}

.start-button li a {
  color: #fff;
}

.link {
  padding: 15px 35px;
  background: #7cc576;
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  border-radius: 3px;
  text-transform: uppercase;
  line-height: 25px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.link:hover {
  text-decoration: none;
  color: #7cc576 !important;
  background: #fff;
}

.link:active,
.link:focus {
  background: #7cc576;
  text-decoration: none;
  color: #fff !important;
}

/* Navigation
---------------------------------*/
.main-nav-outer {
  padding: 0px;
  border-bottom: 1px solid #dddddd;
  box-shadow: 0 4px 5px -3px #ececec;
  background: #fff;
  display: inline-block;
  float: none;
  vertical-align: top;
  width: 100%;
  transition: top 0.5s ease-in-out;
}
.main-nav {
  text-align: center;
  margin: 10px 0 0px;
  padding: 0;
  list-style: none;
  display: block !important ;
  justify-content: space-around;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: block;
}

.main-nav li {
  display: inline;
  margin: 0 1px;
}
.main-nav li a {
  display: inline-block;
  color: #222222;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  line-height: 20px;
  margin: 17px 32px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.main-nav li a:hover {
  text-decoration: none;
  color: #7cc576;
}

.small-logo {
  padding: 0 32px;
}

.main-section {
  padding: 90px 0 110px;
}

/* Services
---------------------------------*/

.service-list {
  padding: 0 0 0 0;
  font-size: 14px;
  margin-bottom: 40px;
}
.service-list-col1 {
  float: left;
  width: 60px;
}
.service-list-col1 i {
  font-style: normal;
  font-size: 38px;
  display: block;
  color: #222;
  font-family: "FontAwesome";
  line-height: 38px;
}
.service-list-col2 {
  overflow: hidden;
}
.main-section.alabaster {
  background: #fafafa;
}

/* Featured Work
---------------------------------*/
.featured-work {
  font-size: 14px;
}
.featured-work h2 {
  text-align: left;
}
.featured-box {
  padding: 0 0 0 0;
  margin-bottom: 25px;
  font-size: 14px;
}
.featured-box h3 {
  margin-bottom: 5px;
}
.featured-box p {
  line-height: 22px;
}
.featured-work p.padding-b {
  padding-bottom: 35px;
}
.featured-box-col1 {
  width: 60px;
  float: left;
}
.featured-box-col1 i {
  display: block;
  line-height: 38px;
  font-family: "FontAwesome";
  font-size: 38px;
  color: #777777;
  font-style: normal;
}
.featured-box-col2 {
  overflow: hidden;
}
.Learn-More {
  display: inline-block;
  padding: 0 5px 0 0;
  color: #7cc576;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: none;
}
.Learn-More i {
  padding-right: 15px;
}

.Learn-More:hover,
.Learn-More:focus {
  text-decoration: none;
  color: #111;
}

/* Portfolio
---------------------------------*/
.Portfolio-nav {
  padding: 0;
  margin: 0 0 45px 0;
  list-style: none;
  text-align: center;
}
.Portfolio-nav li {
  margin: 0 10px;
  display: inline;
}
.Portfolio-nav li a {
  display: inline-block;
  padding: 10px 22px;
  font-size: 12px;
  line-height: 20px;
  color: #222222;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  background: #f7f7f7;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.Portfolio-nav li a:hover {
  background: #7cc576;
  color: #fff;
  text-decoration: none;
}

.portfolioContainer {
  margin: 0 auto;
  padding-left: 15px;
}

.Portfolio-box {
  text-align: center;
  margin-bottom: 30px;
  height: 350px;
  width: 350px;
  overflow: hidden;
  float: left;
  padding: 0;
}
.Portfolio-box img {
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.Portfolio-box img:hover {
  opacity: 0.6;
}

.Portfolio-nav li a.current {
  background: #7cc576;
  color: #fff;
  text-decoration: none;
}

/* no transition on .isotope container */

.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  transition-property: transform, opacity;
}

.main-section.paddind {
  padding-bottom: 60px;
}

/* Clients
---------------------------------*/
.client-part {
  background: url(../img/section-bg1.jpg) center center no-repeat;
  background-size: cover;
  padding: 55px 0;
  text-align: center;
}
.client-part-haead {
  color: #fdfdfd;
  font-size: 28px;
  line-height: 41px;
  margin: 30px 0 10px;
  font-family: "" Open Sans ",sans-serif";
  font-style: italic;
}
.client {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  text-align: center;
}
.client li {
  display: inline;
  margin: 0 15px;
}
.client li a {
  display: inline-block;
}
.client li a img {
  margin-bottom: 15px;
  border-radius: 50%;
}
.client li a:hover {
  text-decoration: none;
}
.client li a h3 {
  color: #ffffff;
}
.client li a span {
  color: #f1f1f1;
}
.quote-right {
  font-style: normal;
  width: 68px;
  height: 68px;
  margin: 0 auto;
  border: 2px solid #7cc576;
  border-radius: 50%;
  display: block;
  line-height: 68px;
  text-align: center;
  font-size: 27px;
  color: #7cc576;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.quote-right:hover {
  color: #fff;
  border: 2px solid #fff;
}

.c-logo-part {
  background: #7cc576;
  padding: 25px 0;
  filter: alpha(opacity=60);
}
.c-logo-part ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.c-logo-part ul li {
  display: inline;
  margin: 0 25px;
}
.c-logo-part ul a {
  display: inline-block;
  margin: 0 20px;
}
.main-section.team {
  padding: 85px 0;
}
.main-section.team h6 {
  margin-bottom: 40px;
}

/* Team
---------------------------------*/
.team-leader-block {
  width: 100%;
  margin: 0 auto;
}
.team-leader-box {
  width: 15%;
  margin-right: 3.82979%;
  margin-left: 3.82979%;
  overflow: hidden;
  text-align: center;
  float: left;
}
.team-leader-box span {
  margin-bottom: 24px;
  display: block;
}
.team-leader {
  width: auto;
  height: auto;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 7px rgba(241, 241, 241, 0.8);
  margin: 7px 7px 25px 7px;
}
.team-leader-shadow {
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  border-radius: 50%;
}
.team-leader-shadow a {
  display: block;
  width: 100%;
  height: 100%;
}
.team-leader:hover .team-leader-shadow {
  box-shadow: inset 0px 0px 0px 148px rgba(17, 17, 17, 0.8);
}
.team-leader:hover ul {
  display: block;
  opacity: 1;
}
.team-leader img {
  display: block;
  border-radius: 50%;
}
.team-leader ul {
  display: block;
  opacity: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  margin-top: -14px;
  z-index: 15;
  transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}
.team-leader ul li {
  display: inline;
  margin: 0 11px;
}
.team-leader ul li a {
  font-family: "FontAwesome";
  display: inline-block;
  font-size: 28px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.team-leader ul li a:hover,
.team-leader ul li a:focus {
  text-decoration: none;
}
.team-leader ul li a.fa-twitter:hover {
  color: #55acee;
}
.team-leader ul li a.fa-facebook:hover {
  color: #3b5998;
}
.team-leader ul li a.fa-leetcode:hover {
  color: #ffa116;
}
.team-leader ul li a.fa-globe:hover {
  color: #7cc576;
}
.team-leader ul li a.fa-linkedin:hover {
  color: #0072b1;
}
.team-leader ul li a.fa-pinterest:hover {
  color: #cb2026;
}
.team-leader ul li a.fa-google-plus:hover {
  color: #dd4b39;
}

/* Talk Business
---------------------------------*/
.business-talking {
  background: url(../img/section-bg2.jpg) top center no-repeat;
  background-size: cover;
  padding: 60px 0 10px;
  text-align: center;
}
.business-talking h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 70px;
  text-transform: uppercase;
  font-size: 42px;
  color: #fff;
}

/* Contact
---------------------------------*/
.main-section.contact {
  padding: 90px 0 100px;
}

.main-section.contact {
  background: url(../img/bg-map.png) left 190px no-repeat;
}
.contact-info-box {
  font-size: 15px;
  margin: 0 0 14px 68px;
  padding-left: 0;
}
.contact-info-box h3 {
  font-size: 15px;
  font-weight: 400;
  float: left;
  width: 102px;
  margin-right: 12px;
  line-height: 28px;
}
.contact-info-box h3 i {
  font-style: normal;
  font-size: 18px;
  color: #222222;
  font-family: "FontAwesome";
  font-weight: normal;
  margin-right: 7px;
}
.contact-info-box span {
  line-height: 28px;
  display: block;
  overflow: hidden;
}
.social-link {
  padding: 35px 0;
  margin: 0 0 0 68px;
  display: block;
  overflow: hidden;
  list-style: none;
}
.social-link li {
  float: left;
  margin-right: 8px;
}
.social-link li a {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  color: #fff;
  background: #222222;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.social-link li a:hover,
.social-link li a:focus {
  text-decoration: none;
}
.twitter a:hover {
  background: #55acee;
}
.facebook a:hover {
  background: #3b5998;
}
.leetcode a:hover {
  background: #ffa116;
}
.linkedin a:hover {
  background: #0072b1;
}
.pinterest a:hover {
  background: #cb2026;
}
.gplus a:hover {
  background: #fff;
  color: #000;
}

.form {
  margin: 0 66px 0 30px;
}
.input-text {
  padding: 15px 16px;
  border: 1px solid #ccc;
  width: 100%;
  height: 50px;
  display: block;
  border-radius: 4px;
  font-size: 15px;
  color: #aaa;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 15px 0;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.input-text:focus {
  border: 1px solid #7cc576;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(124, 197, 118, 0.3);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(124, 197, 118, 0.3);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(124, 197, 118, 0.3);
}

.input-text.text-area {
  height: 165px;
  resize: none;
  overflow: auto;
}
.input-btn {
  width: 175px;
  height: 50px;
  background: #7cc576;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border: 0px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.input-btn:hover {
  background: #111;
  color: #fff;
}

/* Footer
---------------------------------*/

.footer {
  background: url(../img/pw_maze_black_2X.png) left top repeat;
  padding: 35px 0 35px;
}
.footer-logo {
  margin: 15px auto 35px;
  width: 76px;
}
.copyright {
  color: #cccccc;
  font-size: 14px;
  display: block;
  text-align: center;
}
.copyright a {
  color: #7cc576;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.copyright a:hover {
  color: #fff;
}

.res-nav_click {
  line-height: 38px;
  font-family: "FontAwesome";
  font-size: 38px;
  text-decoration: none !important;
  color: #777777;
  font-style: normal;
  display: none;
  width: 42px;
  height: 27px;
  margin: 20px auto;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.res-nav_click:hover,
.res-nav_click:active,
.res-nav_click:focus {
  color: #7cc576 !important;
}

.portfolioContainer {
  max-width: 1140px;
}

/* Animation Timers
---------------------------------*/
.delay-02s {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.delay-03s {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.delay-04s {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.delay-05s {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.delay-06s {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.delay-07s {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}
.delay-08s {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

.delay-09s {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}
.delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
.delay-12s {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

/* Projectos
---------------------------------*/

.caption-style-1 {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.caption-style-1 li {
  float: left;
  position: relative;
  overflow: hidden;
  padding: 20px 30px;
}

.caption-style-1 li:hover .caption {
  opacity: 1;
}

.caption-style-1 img {
  margin: 0px;
  padding: 0px;
  float: left;
  z-index: 4;
  width: 320px;
  height: 225px;
}

.caption-style-1 .caption {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.45s ease-in-out;
  -moz-transition: all 0.45s ease-in-out;
  -o-transition: all 0.45s ease-in-out;
  -ms-transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
.caption-style-1 .blur {
  background-color: rgba(0, 0, 0, 0.65);
  height: 225px;
  width: 320px;
  z-index: 5;
  position: absolute;
}

.caption-style-1 .caption-text h1 {
  text-transform: uppercase;
  font-size: 24px;
}

.caption-style-1 .caption-text {
  z-index: 10;
  color: #fff;
  position: absolute;
  width: 320px;
  height: 225px;
  text-align: center;
  top: 100px;
}

@media (max-width: 850px) {
  .hidden-mobile {
    display: none !important;
  }
}

.icon {
  width: 25px;
}

.invert {
  filter: invert(100%);
}
